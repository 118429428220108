import register from 'preact-custom-element';
import CartDrawerCheckoutButton from './CartDrawerCheckoutButton';
import CartDrawerHeader, {type CartDrawerHeaderProps} from './CartDrawerHeader';
import CartDrawerItemList from './CartDrawerItemList';
import DonationSelector from '@/components/buy-stack/DonationSelector';
import SummaryGrid, {SummaryGridLine} from '@/components/SummaryGrid';
import {
  checkout,
  getCart,
  getDonationCategory,
  updateLineItem,
} from '@/cart/cart-store';
import {useLocale} from '@/localization/use-locale';
import {logError, logInfo} from '@/utilities/log';
import placeholderCartSectionSettings from '@/settings/placeholder/cart-section';
import {z} from 'zod';
import formatCurrency from '@/utilities/format-currency';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';

// Report the feature flag value to DD for tracking purposes
getFeatureFlag('accelerated');

register((props: Pick<CartDrawerHeaderProps, 'title' | 'emptyTitle'>) => {
  const cart = getCart();
  const itemCount = cart?.item_count ?? 0;

  return <CartDrawerHeader itemCount={itemCount} {...props}></CartDrawerHeader>;
}, 'cart-drawer-header');

register(() => {
  const cart = getCart();
  const locale = useLocale();

  /**
   * Update the quantity of a line item in the cart.
   */
  const handleQuantityUpdate = async (line: number, quantity: number) => {
    await updateLineItem({
      line,
      quantity,
    });
  };

  return (
    <CartDrawerItemList
      cart={cart ?? undefined}
      locale={locale}
      onQuantityUpdate={(l, q) => void handleQuantityUpdate(l, q)}
    />
  );
}, 'cart-drawer-item-list');

register(
  ({
    heading,
    selectLabel,
    selectOptions,
    terms,
  }: {
    heading?: string | null;
    selectLabel?: string | null;
    selectOptions?: string | null;
    terms?: string | null;
  }) => {
    const placeholder = placeholderCartSectionSettings;

    let options = placeholder.donation_select_options.options;
    const result = z
      .array(z.string())
      .nullable()
      .optional()
      .safeParse(JSON.parse(selectOptions ?? '[]'));

    if (result.success && result.data) {
      options = result.data;
    }

    return (
      <DonationSelector
        name="cart-donation-category"
        onUpdate={(v) => void setDonationCategory(v)}
        settings={{
          donation_heading: heading ?? placeholder.donation_heading,
          donation_select_label:
            selectLabel ?? placeholder.donation_select_label,
          donation_select_options: {options},
          donation_terms_conditions:
            terms ?? placeholder.donation_terms_conditions,
        }}
      />
    );
  },
  'cart-drawer-donation-selector'
);

register(
  ({
    shippingLineLabel,
    freeShippingLabel,
  }: {
    shippingLineLabel?: string;
    freeShippingLabel?: string;
  }) => {
    const cart = getCart();
    const locale = useLocale();

    const formatPrice = (price?: number): string => {
      return formatCurrency((price ?? 0) / 100, cart?.currency, locale);
    };

    const placeholder = placeholderCartSectionSettings;

    return (
      <SummaryGrid
        className="px-6"
        lines={
          [
            cart?.total_discount && {
              label: 'Total Savings',
              value: cart?.total_discount ? (
                <span className="text-dark-blue-gray">
                  -{formatPrice(cart?.total_discount)}
                </span>
              ) : (
                '-'
              ),
            },
            {
              label: shippingLineLabel ?? placeholder.shipping_line_label,
              value: (
                <span className="text-primary-blue">
                  {freeShippingLabel ?? placeholder.free_shipping}
                </span>
              ),
            },
            {
              label: 'Total',
              value: formatPrice(cart?.total_price),
            },
          ].filter(Boolean) as SummaryGridLine[]
        }
      />
    );
  },
  'cart-drawer-summary-grid'
);

register(({label}: {label?: string}) => {
  const cart = getCart();

  const handleCheckout = async () => {
    try {
      // If the donation category is not set, default to the first option
      const category =
        getDonationCategory() ??
        placeholderCartSectionSettings.donation_select_options.options[0];
      await setDonationCategory(category);
      await checkout();
    } catch (error) {
      logError(error);
    }
  };

  if (!cart) return <div></div>;

  return (
    <CartDrawerCheckoutButton
      label={label ?? placeholderCartSectionSettings.checkout_button_label}
      onCheckout={() => void handleCheckout()}
    ></CartDrawerCheckoutButton>
  );
}, 'cart-drawer-checkout-button');

/**
 * Sets the donation category for the items in the cart.
 *
 * @param category - The donation category to set.
 * @returns A promise that resolves when the donation category is successfully updated.
 * @throws If there is an error updating the donation category.
 */
async function setDonationCategory(category: string) {
  const cart = getCart();
  if (!cart) return;
  try {
    await Promise.all(
      cart.items.map((item) =>
        updateLineItem({
          id: item.id.toString(),
          // quantity must be specified, otherwise Shopify resets it to 1
          quantity: item.quantity,
          properties: {
            _buy_one_give_ten_category: category,
          },
        })
      )
    );
    logInfo('DonationCategoryUpdated', {category, cart});
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingDonationCategory', category, cart});
    throw error;
  }
}
